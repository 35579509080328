import React from "react"

export const SiteContext = React.createContext()

const Provider = (props) => {
  return (
    <SiteContext.Provider value={{}}>{props.children}</SiteContext.Provider>
  )
}
// eslint-disable-next-line import/no-anonymous-default-export
export default ({ element }) => <Provider>{element}</Provider>
